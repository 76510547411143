import { Box, BoxProps } from '@chakra-ui/react';

// define the BrochureNumber type to extend the BoxProps type
export type BrochureNumberProps = {
  number: number;
} & BoxProps;

export const BrochureNumber = ({ number, ...rest }: BrochureNumberProps) => (
  <Box
    position="absolute"
    zIndex="99"
    left="50%"
    marginLeft={-5}
    top={-5}
    bgColor="rgba(0, 0, 0, 0.87);"
    boxSize={10}
    display="flex"
    fontWeight={500}
    alignItems="center"
    justifyContent="center"
    lineHeight={1}
    borderRadius="full"
    color="white"
    letterSpacing="-0.3px"
    transition="all 0.15s ease-in-out"
    border="1px solid rgba(255, 255, 255, 0.87)"
    dropShadow="0 1px 4px -3px rgba(0, 0, 0, 0.15)"
    transitionDuration="1"
    transitionTimingFunction="ease-in"
    data-testid="brochure-number"
    {...rest}
    sx={{
      '@media print': {
        color: 'black',
      },
    }}
  >
    {number > 100 ? (
      // split the first digit number to a span, then display the rest
      <>
      <span>{number.toString()}</span>
        {/* <Box as="span">{number.toString().split('')[0]}</Box>
        <span>{number.toString().slice(1)}</span> */}
      </>
    ) : (
      number
    )}
  </Box>
);
